body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Tahoma', 'Verdana' sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 {
  margin: 0;
  padding: 0;
}

h2 {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
  padding: 0;
}

.content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  /*
  border-color: rgb(204, 22, 83);
  border-style: solid;
  border-width: 5px;
  */
}

Header {
  background-color: #333;
  color: white;
}

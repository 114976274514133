.dark .welcome-body {
    background-color: #12121a;
    color: white;
}

.light .welcome-body {
    background-color: #ffffff;
    color: black;
}

.center {
    /*
    border-color: aquamarine;
    border-style: solid;
    border-width: 5px;
    */

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.column {
    width: 50% !important;
    float: left;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    padding: 70px 0;
}

/* Make both side same height */
.row {
    display: flex;
}
  
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* left side */
.left {
    position: relative;
    /* background-color: #aaa; */
}

/* remove purple underline */
.left a {
    text-decoration: none;
}

.right img {
    border-radius: 50%;
    height: 400px;
    width: 400px;
}

.socialButton {
    height: 35px;
    width: 35px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.3s;
}

.socialButton:hover {
    transform: scale(1.2);
}
.project-box-outer {
    border-radius: 5%;
    width: 85%;
    margin: 20px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    flex-direction: column;
}

.dark .project-box-outer {
    background-color: #18182b;
    border: 3px solid #11111f;
}

.light .project-box-outer {
    background-color: white;
}

.tag {
    display: inline-block;
    border-bottom: 2px solid #54ab5a;
    padding: 3px;
    margin: 3px;
    font-size: 15px;
    text-decoration: none;
}

.project-img {
    max-width: 100%;
    max-height: 100px;
    width: auto;
    height: auto;
    overflow: hidden;
}

.project-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

td {
    /* border: 1px solid; */
    padding: 10px;
}

.projectLinks {
    float: right;
}

table {
    flex-grow: 1; /* Allow the table to grow and fill available space */
}

/* Game Engines */
.unity, .phaser, .facepunchsteamworks {
    border-bottom: 2px solid #5ab842;
}

/* Frontend Development */
.html, .css, .javascript, .flutter {
    border-bottom: 2px solid #e34c26;
}

/* Backend Development */
.nodejs, .csharp, .java {
    border-bottom: 2px solid #007396;
}

/* Cloud Services */
.aws, .firebase {
    border-bottom: 2px solid #ff9900;
}

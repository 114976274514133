.dark .projects-body {
    background-color: #080810;
    color: white;
}

.light .projects-body {
    background-color: #ebedef;
    color: black;
}

.content {
    width: 100%;
    max-width: 1200px;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.projects-grid > * {
    box-sizing: border-box;
    transition: transform 0.3s ease;
}

.projects-grid>*:hover {
    transform: translateY(-10px);
}

/* 1 box per row on small screens */
@media (max-width: 850px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }
}